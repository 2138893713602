
























































































































import {Component, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterOrganization from '@/components/filters/FilterOrganization.vue'
import {Organization} from '@/model/resource/Organization'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'
import {ListOrganizationSchema} from '@/schema/resource/Organization/ListOrganizationSchema'
import {ExportOrganizationSchema} from '@/schema/resource/Organization/ExportOrganizationSchema'

@Component({
  components: {FilterToggle, FilterOrganization},
})
export default class ListOrganizationView extends Mixins(MixinRouteMatch) {
  schema = new ListOrganizationSchema()
  collection = new OrganizationCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: Organization) {
    this.$nav.pushByName('editOrganization', item.$id)
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new OrganizationCollection().clearFilters().addFilter(params)

    await coll.listExportOrganization()
    this.$xlsx.downloadFromSchema(coll.items, new ExportOrganizationSchema())
  }

  async removeItem(item: Organization) {
    await this.$dialog.remove(item, 'schema.ListOrganization.confirmRemove')
    await item.removeOrganization()
    await this.collection.queryAsPage()
  }
}
