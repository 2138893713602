/**
 * Export Schema of Organization
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {Organization} from '@/model/resource/Organization'

/* TODO: review generated schema */
export class ExportOrganizationSchema extends DefaultSchema {
  readonly name = 'ExportOrganization'

  readonly fieldSet: FieldSet<Organization> = {
    idOrganizationPk: schema => schema.model.idOrganizationPk,
    title: schema => schema.model.title,
    colorHex: schema => schema.model.colorHex,
    logoUrl: schema => schema.model.logoUrl,
    repositoryUrl: schema => schema.model.repositoryUrl,
    location: schema => schema.model.location,
    establishedYear: schema => schema.model.establishedYear,
  }
}
