/**
 * List Schema of Organization
 * @author Lopes
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Organization} from '@/model/resource/Organization'

export class ListOrganizationSchema extends DefaultSchema {
  readonly name = 'ListOrganization'

  readonly fieldSet: FieldSet<Organization> = {
    idOrganizationPk: (): FieldComponent => ({
      is: Component.Render,
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    colorHex: (): FieldComponent => ({
      is: Component.Render,
    }),
    logoUrl: (): FieldComponent => ({
      is: Component.Render,
    }),
    repositoryUrl: (): FieldComponent => ({
      is: Component.Render,
    }),
    location: (): FieldComponent => ({
      is: Component.Render,
    }),
    establishedYear: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
