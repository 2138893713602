
















import {Component, Prop, Watch, Vue} from 'vue-property-decorator'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'
import {FilterOrganizationSchema} from '@/schema/resource/Organization/FilterOrganizationSchema'

@Component
export default class FilterOrganization extends Vue {
  @Prop({type: Object, required: true}) collection!: OrganizationCollection

  schema = new FilterOrganizationSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = []

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryAsPage())
  }
}
